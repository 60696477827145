import { OffCanvasComponent } from '../../components/offcanvas';
import { bpHelper } from '../../components/utils';
import { closeAllModal } from '../../design_system/components/modal';
import { yieldToMain } from '../../components/helpers';

let gistFullyLoaded = false;

function cleanGistEvent() {
  if (typeof document.getEventListeners !== 'function') {
    return;
  }
  // Clean des event gist si possible
  // console.log("try to clean gist");
  const listeners = document.getEventListeners();
  const eventsCleaned = [];
  for (const eventName in listeners) {
    // console.log(eventName);
    if (eventName.startsWith('turbo:') || eventName.startsWith('ajax:') || eventName.startsWith('show.') || eventName.startsWith('hide.')) {
      continue;
    }

    // console.log(eventName+ " to process");
    const listener = listeners[eventName];
    // console.log(listener);
    for (const index in listener) {
      const eventObj = listener[index];
      if (typeof eventObj !== 'object' || eventObj.selector) {
        continue;
      }
      // console.log(eventObj);
      const fct = eventObj.listener;
      if (fct && fct.toString() === 'function(e){_cfCommonFunctions.prototype.gtHandlerOfListener.apply(this,n&&n instanceof Array?n:[])}') {
        document.removeEventListener(eventName, fct);
        eventsCleaned.push(eventName);
      }
    }
  }
  if (eventsCleaned.length > 0) {
    console.log(eventsCleaned.join(',') + ' cleaned for gist');
  }
}

function afterGistInit() {
  // console.log("afterGistInit");
  // on cache l'iframe Gist pour éviter que le messenger ne clignotte
  $('#gist-app').hide();
}

function updateUnreadIndicator() {
  const counter = window.gistUnreadCount;
  // console.log("Updating unread indicator : " + counter + ' messages')
  const indicator = $('.gist-unread-indicator');
  const textContainer = $('.gist-unread-indicator .counter');
  if (counter > 0) {
    textContainer.text(counter);
    indicator.show();
    indicator.find('.plural').toggle(counter > 1);
    indicator.find('.singular').toggle(counter === 1);
    // showLauncherOnLargeScreens();
  } else {
    indicator.hide();
  }
}

function afterGistReady() {
  console.log('Gist Chat Ready !');
  if (!window.gist) {
    return;
  }
  gistFullyLoaded = true;

  // on met dans le DOM le bouton d'élargissement
  $("<div id='gist-enlarge-chat'></div>").appendTo('body');

  if ($('#reset-gist-session').length > 0) {
    window.gist.chat('shutdown');
  }
  // on cache tout au début
  afterGistMessengerClosed();
  // on réaffiche l'ifram Gist cachée temporairement à l'init
  updateUnreadIndicator();
  identifyGist();

  // todo  replace the check $('.gist-messenger-iframe').css('visibility') != 'visible' when a GIST JS API will be available
  if (window.gistUnreadCount > 0 || $('.gist-messenger-iframe').css('visibility') !== 'hidden') {
    // console.log('Gist messenger is not opened : showing launcher')
    showLauncherOnLargeScreens();
    if ($('.gist-messenger-iframe').css('visibility') !== 'hidden') {
      afterGistMessengerOpened();
    }
  }

  $('.chat-availability-status.gist-available').show();
  $('.chat-availability-status.gist-waiting').hide();
  $('.chat-availability-status.gist-down').hide();
  $('#gist-app').show();

  setTimeout(function() {
    if ($('#gist-bot-launcher').length > 0) {
      goBot($('#gist-bot-launcher').data('gist-link'));
    }
  }, 3000);
}

function afterGistMessengerOpened() {
  // console.log("Gist messenger has been displayed !");
  window.gist.chat('showLauncher');
  if (bpHelper.isMatching('md')) {
    $('#gist-enlarge-chat').show();
  }
}

function afterGistMessengerClosed() {
  // console.log("Gist messenger has just been removed !");
  setTimeout(function() {
    window.gist.chat('hideLauncher');
  }, 1);
  $('#gist-enlarge-chat').hide();
}

function identifyGist() {
  const gistDiv = $('#gist-infos');
  if (gistDiv.length > 0 && gistDiv.data('email') && gistDiv.data('id')) {
    // console.log("Identifying on Gist the user " + gistDiv.data('id'))
    window.gist.identify(gistDiv.data('id').toString(), {
      email: gistDiv.data('email'),
      user_id: gistDiv.data('id'),
      auto_connect: gistDiv.data('url'),
      website_url: gistDiv.data('url'),
    });
  }
}

function showLauncherOnLargeScreens() {
  if (bpHelper.isMatching('md')) {
    window.gist.chat('showLauncher');
  }
}

function openNewBot(botId) {
  // use the JS call in order not to change url (id must be integer)
  window.gist.trigger('bot', botId);
}

// utilisé aussi dans le partial chat_bubbles
function goBot(botId) {
  // le location.hash déclenche un event turbo before visit à priori qui fait que le chat est retiré.
  preventGistUnloadFlag = true;
  location.hash = botId;
  setTimeout(function() {
    preventGistUnloadFlag = false;
  }, 100);
}
function preventGistUnload() {
  preventGistUnloadFlag = true;
  setTimeout(function() {
    preventGistUnloadFlag = false;
  }, 100);
}
document.arrive('#gist-enlarge-chat', function(element) {
  const $item = $(element);
  let state = {
    isOpen: false,
  };
  const render = function() {
    const label = state.isOpen ? 'Réduire' : 'Agrandir';
    $item.html(label);
  };
  const toggle = function() {
    state = Object.assign({}, state, {
      isOpen: !state.isOpen,
    });
    render();
    $item.toggleClass('opened', state.isOpen);
    $('#gist-app .gist-messenger-iframe').toggleClass('large-version', state.isOpen);
    $('#gist-app .gist-messenger-iframe').toggleClass('small-version', !state.isOpen);
  };
  $item.off('click', toggle);
  $item.on('click', toggle);
  render();
});

document.arrive('.ask-support-action', function(element) {
  const $item = $(element);
  const clickAskSupportAction = function(event) {
    // entre xs et sm on affiche le offcanvas, sinon une modale
    if (bpHelper.isMatching('md', true)) {
      const body = $('#modal_support_action .modal-body').html();
      return new OffCanvasComponent("Besoin d'aide ?", body).show();
    } else {
      const modalElement = document.getElementById('modal_support_action');
      const controller = Stimulus.getControllerForElementAndIdentifier(modalElement, 'modal');
      // hide all modals
      closeAllModal();
      controller.show();
    }
  };
  $item.off('click', clickAskSupportAction);
  $item.on('click', clickAskSupportAction);
});

document.arrive('.ask-gist-action', function(element) {
  const $item = $(element);
  const clickOnAskGistAction = function(event) {
    setTimeout(function() {
      closeAllModal();

      showLauncherOnLargeScreens();
      window.gist.chat('open');
      afterGistMessengerOpened();
    }
    , 100);
    return false;
  };
  $item.off('click', clickOnAskGistAction);
  $item.on('click', clickOnAskGistAction);
});

document.addEventListener('turbo:load', async function() {
  // Yield to the main thread:
  await yieldToMain();
  // setting things up
  // console.log("Setup  Gist from help.JS")
  const $document = $(document);
  $document.off('initGistChat');
  $document.on('initGistChat', afterGistInit);
  $document.off('gistChatReady');
  $document.on('gistChatReady', afterGistReady);
  $document.off('messenger:opened');
  $document.on('messenger:opened', afterGistMessengerOpened);
  $document.off('messenger:closed');
  $document.on('messenger:closed', afterGistMessengerClosed);
  $document.off('onGistUnreadCountChange');
  $document.on('onGistUnreadCountChange', updateUnreadIndicator);

  // loadGistDownAlert
  clearTimeout(window.gistFullyLoadedTimeout);
  window.gistFullyLoadedTimeout = setTimeout(function() {
    if (!gistFullyLoaded) {
      console.error('fail to load gist');
      $('.chat-availability-status.gist-waiting').hide();
      $('.chat-availability-status.gist-down').show();
    }
  }, window.delayToLoadGist + 10000);
});

let hasGistScriptLoaded = false;
let preventGistUnloadFlag = false; // utilisé dans le lancement des bots
clearTimeout(window.gistLongTimeoutloaded);
clearTimeout(window.gistTimeoutloaded);
clearTimeout(window.gistLoadTimeout);
const preloadGist = function(workspaceId, delayToLoadGist) {
  hasGistScriptLoaded = false;
  preventGistUnloadFlag = false;
  cleanGistEvent();

  const loadEvent = 'turbo:load';
  const unloadEvent = 'turbo:visit';

  const loadHandler = function() {
    // console.log("loadHandler processing. GistScriploaded : " + hasGistScriptLoaded)
    document.removeEventListener(loadEvent, loadHandler);
    document.addEventListener(unloadEvent, unloadHandler);

    if (!hasGistScriptLoaded) {
      window.gistTimeoutloaded = setTimeout(function() {
        // console.log("Setting up Gist")
        gist.setAppId(workspaceId);
        gist.trackPageView();
        hasGistScriptLoaded = true;
      }, delayToLoadGist + 500);
    }
    window.gistLongTimeoutloaded = setTimeout(function() {
      if (!hasGistScriptLoaded) {
        // console.log("Setting up Gist : second try")
        gist.setAppId(workspaceId);
        gist.trackPageView();
        hasGistScriptLoaded = true;
      }
    }, delayToLoadGist + 3000);
  };

  const unloadHandler = function() {
    if (!preventGistUnloadFlag) {
      document.removeEventListener(unloadEvent, unloadHandler);
      if (hasGistScriptLoaded && window.gist) {
        // remove interval for ping if any, otherwise it can
        window.gist.trackPageView('chatShutdown');
        delete (window.gist);
      }
      hasGistScriptLoaded = false;
    }
  };
  window.gistLoadTimeout = setTimeout(function() {
    (function(d, h, w) {
      // console.log("Creating Gist widget");
      const gist = w.gist = w.gist || [];
      gist.methods = ['trackPageView', 'identify', 'track', 'setAppId'];
      gist.factory = function(t) {
        return function() {
          const e = Array.prototype.slice.call(arguments);
          e.unshift(t);
          if (typeof gist.push === 'function') {
            gist.push(e);
          }
          return gist;
        };
      };
      for (const method of gist.methods) {
        gist[method] = gist.factory(method);
      }
      const scriptElement = d.createElement('script');
      scriptElement.src = 'https://widget.getgist.com';
      scriptElement.async = !0;
      const element = d.getElementsByTagName(h)[0];
      element.appendChild(scriptElement);
      scriptElement.addEventListener('load', function(e) {}, !1);
    })(document, 'head', window);
  }, delayToLoadGist);

  document.removeEventListener(loadEvent, loadHandler);
  document.addEventListener(loadEvent, loadHandler);
};

document.addEventListener('turbo:frame-load', function(event) {
  // if turboAction is defined for a turbo_frame loading then turbo will trigger a visit
  if (event.target && event.target.dataset.turboAction) {
    preventGistUnload();
  }
});

global.preloadGist = preloadGist;
export { goBot, cleanGistEvent, openNewBot };
